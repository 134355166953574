<template>
  <b-card-code
    title="Pengaturan Produk"
  >
  <b-card-body>
        <b-row class="my-2">
          
            <!-- Left: Form Input -->
            <b-col
              cols="12"
              md="5"
            >   
              <b-form @submit.prevent="created">           
                <b-form-group>
                    <b-card-text class="mb-0"> {{ $t('Form.Product.Product') }} </b-card-text>
                    <v-select
                    v-model="product"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="products"
                    label="name"
                    />
                </b-form-group>
                <b-form-group>
                  <b-card-text class="mb-0"> {{ $t('Form.Product.Id') }} </b-card-text>
                  <b-form-input
                    v-model="id"
                    id="mc-last-name"
                  />
                </b-form-group>
              </b-form>
              <div class="d-flex flex-column flex-sm-row pt-1">             
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                  @click="created"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                  />
                  <span>{{ $t('Submit') }}</span>
                </b-button>

                <b-button
                  variant="outline-secondary"
                  class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                >
                  <feather-icon
                    icon="RotateCwIcon"
                    class="mr-50"
                  />
                  <span>{{ $t('Reset') }}</span>
                </b-button>
              </div>
            </b-col>

            <!-- Right: Product Details -->
            <b-col
              cols="12"
              md="7"
            >
              <!-- Details -->
            </b-col>        
        </b-row>
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadioGroup, BInputGroup, BCardText, BCardBody
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'
import { getUserData } from '@/auth/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    BInputGroup,
    vSelect,
    BCardText,
    BCardBody
  },
  directives: {
    Ripple,
  },

  data: () => ({
    id: '',
    product: '',
    products: [],
    idInstansi: ''
  }),
  methods: {
    fetchDataListProduct(idInstansi) { 
      moduleApi.fetchDataListProducs(idInstansi)
        .then(response => {       
            this.products  = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
    
    created() { 
      moduleApi.settleProduct({
        external_id: this.id,
        product_id: this.product.id,
        product_name: this.product.name,
        isntansi_id: this.idInstansi
      })
        .then(response => {
            this.$router.replace({name: 'product'})
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  created () {
    const userData = getUserData()
    this.idInstansi = userData.idInstansi

    this.fetchDataListProduct(this.idInstansi)
  },
  setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n 
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
